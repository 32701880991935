<template>
  <v-dialog
    v-model="confirm.sync"
    container="sm"
    scrollable
    persistent
  >
    <v-card>
      <div class="box" tpd="xl" halign="between">
        <div>
          {{ confirm.title }}
        </div>
        <button @click="toggleConfirm">
          <v-icon icon="mdi-window-close" size="x-small"></v-icon>
        </button>
      </div>
      <hr class="hr" bg="5xs">
      <div tpd="xl" v-html="confirm.contents" />
      <hr class="hr" bg="5xs">
      <div class="box" tpd="xl" gap="sm" halign="end">
        <v-btn
          :title="confirm.button"
          prepend-icon="mdi-check"
          color="secondary"
          @click="toggleConfirmCallback"
        >
          {{ confirm.button }}
        </v-btn>
        <v-btn
          :title="$t('cancel')"
          variant="tonal"
          @click="toggleConfirm"
        >
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  setup() {
    
    // 글로벌 스토어 호출
    const { confirm } = storeToRefs(useGlobalStore())
    const globalStore = useGlobalStore()   

    return {
      confirm,
      globalStore
    }
  },
  methods: {
    toggleConfirm () {
      this.globalStore.toggleConfirm()
    },
    toggleConfirmCallback () {
      if (typeof this.confirm.callback === 'function') {
        (this.confirm.callback as () => void)()
      }
    }
  }
}
</script>